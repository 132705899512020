// extracted by mini-css-extract-plugin
export var hero = "jw_ka";
export var hero_appstore = "jw_b5a";
export var hero_btn = "jw_b0a";
export var hero_buttons = "jw_bZa";
export var hero_content = "jw_pa";
export var hero_inner = "jw_na";
export var hero_link = "jw_b1a";
export var hero_pic = "jw_sa";
export var hero_pic_item = "jw_b6a";
export var hero_pic_item__laptop = "jw_b8a";
export var hero_pic_item__mobile = "jw_b7a";
export var hero_video = "jw_b2a";
export var hero_video_prev = "jw_b3a";
export var hero_video_title = "jw_b4a";
export var video = "jw_va";
export var videoWrapper = "jw_ta";