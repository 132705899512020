// extracted by mini-css-extract-plugin
export var calculator = "js_bDa";
export var calculator_areas = "js_bMa";
export var calculator_container = "js_bKa";
export var calculator_cta = "js_bHa";
export var calculator_inner = "js_bFa";
export var calculator_saving = "js_bPa";
export var calculator_sliders = "js_bNa";
export var calculator_submit = "js_bLa";
export var calculator_title = "js_bGa";
export var calculator_titleForm = "js_bJa";